import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import RequireAuth from "../hocs/RequireAuth";
import BaseLayout from "../layout/BaseLayout";
import { Input, message } from "antd";
import StarInspiredPlanCard from "../subComponents/StarInspiredPlanCard";
import selena from "../assets/images/nutritionPlans/selena.png";
import { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

const StarInspiredPlans = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState("");
  const [prevSearchQuery, setPrevSearchQuery] = useState("");

  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    if (searchQuery !== prevSearchQuery) {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(() => {
        if (searchQuery !== prevSearchQuery) {
          setPrevSearchQuery(searchQuery);
        }
      }, 1000);
    }
  }, [searchQuery, prevSearchQuery]);

  return (
    <BaseLayout title={t("star_inspired_plans")}>
      {contextHolder}
      <div className="w-full overflow-scroll flex flex-col gap-4 p-6">
        <Input
          placeholder={t("search")}
          className="w-full text-body-medium rounded-full"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          suffix={<SearchOutlined className="text-primary text-body-large" />}
        />
        <StarInspiredPlanCard
          image={selena}
          name="Selena Gomez"
          days={30}
          kcal={1300}
          tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
          items={["7 days a week", "30 days", "5 meals a day", "Tasty dishes"]}
        />
        <StarInspiredPlanCard
          image={selena}
          name="Virat Kohli"
          days={30}
          kcal={1300}
          tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
          items={["7 days a week", "30 days", "5 meals a day", "Tasty dishes"]}
        />
        <StarInspiredPlanCard
          image={selena}
          name="MS Dhoni"
          days={30}
          kcal={1300}
          tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
          items={["7 days a week", "30 days", "5 meals a day", "Tasty dishes"]}
        />
      </div>
    </BaseLayout>
  );
};

export default RequireAuth(StarInspiredPlans);
