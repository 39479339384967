import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import RequireAuth from "../hocs/RequireAuth";
import BaseLayout from "../layout/BaseLayout";
import { message } from "antd";
import nutritionistPlansBg from "../assets/images/nutritionPlans/home.png";
import PrimaryButton from "../subComponents/PrimaryButton";
import StarInspiredPlanCard from "../subComponents/StarInspiredPlanCard";
import selena from "../assets/images/nutritionPlans/selena.png";

const NutritionistPlans = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <BaseLayout
      title={t("plans")}
      headerBackgroundColor="bg-white"
      showDivider={false}
    >
      {contextHolder}
      <div className="w-full overflow-scroll flex flex-col gap-4 pb-6">
        <div className="w-full bg-gradient-to-b from-white to-primaryBlue/10 rounded-b-3xl p-4 flex flex-col gap-4 justify-center items-center">
          <img src={nutritionistPlansBg} className="w-1/2" />
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="w-full text-center text-title-large text-blue-500">
              {t("create_your_plan")}
            </div>
            <div className="w-full text-center text-neutral-700 text-body-large">
              {t("create_a_customised_plan_tailored_for_your_goals_needs")}
            </div>
          </div>
          <PrimaryButton
            color="bg-primaryBlue"
            showIcon={false}
            text={t("create_personalised_plan")}
            onClick={() => {
              navigate("/nutritionist-plans");
            }}
          />
        </div>
        <div className="w-full px-6">
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex justify-between items-center">
              <div className="text-body-large text-neutral-700">
                {t("star_inspired_plans")}
              </div>
              <div
                className="text-label-large text-primary cursor-pointer"
                onClick={() => {
                  navigate("/star-inspired-plans");
                }}
              >
                {t("view_all")}
              </div>
            </div>
            <StarInspiredPlanCard
              image={selena}
              name="Selena Gomez"
              days={30}
              kcal={1300}
              tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
              items={[
                "7 days a week",
                "30 days",
                "5 meals a day",
                "Tasty dishes",
              ]}
            />
            <StarInspiredPlanCard
              image={selena}
              name="Virat Kohli"
              days={30}
              kcal={1300}
              tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
              items={[
                "7 days a week",
                "30 days",
                "5 meals a day",
                "Tasty dishes",
              ]}
            />
            <StarInspiredPlanCard
              image={selena}
              name="MS Dhoni"
              days={30}
              kcal={1300}
              tags={["Low-Carb", "Balanced", "Vegetarian", "High Protein"]}
              items={[
                "7 days a week",
                "30 days",
                "5 meals a day",
                "Tasty dishes",
              ]}
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default RequireAuth(NutritionistPlans);
