import { message } from "antd";
import { getLocation } from "../../utilities/helper";
import { useEffect, useState } from "react";

const LocationTestPage = () => {
  const [location, setLocation] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const receiveLocationHandler = (data) => {
    try {
      const dataParsed = JSON.parse(data);
      setLocation(dataParsed.toString());
    } catch (e) {
      setLocation(data);
      messageApi.error(data);
    }
  };

  useEffect(() => {
    window.receiveLocation = receiveLocationHandler;
    window.receiveLocationData = receiveLocationHandler;
    return () => {
      window.receiveLocation = null;
      window.receiveLocationData = null;
    };
  }, []);

  return (
    <div className="min-h-full flex flex-col gap-4 items-center justify-center">
      {contextHolder}
      <h1>Location Test</h1>
      <p>Location: {location}</p>
      <button onClick={() => getLocation()}>Get Location</button>
    </div>
  );
};

export default LocationTestPage;
