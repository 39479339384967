import { useTranslation } from "react-i18next";
import primaryTick from "../assets/images/icons/primaryTick.svg";
import PrimaryButton from "./PrimaryButton";
import { useNavigate } from "react-router-dom";

const StarInspiredPlanCard = ({
  image,
  name,
  days,
  kcal,
  tags = [],
  items = [],
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      className="w-full rounded-xl p-4 flex flex-col gap-4"
      style={{
        backgroundImage: `linear-gradient(to bottom left, rgba(255, 255, 255, 0.6), white, #FFF3EA), url(${image})`,
        backgroundSize: "cover",
        backgroundPosition: "top",
      }}
    >
      <div className="flex flex-col">
        <div className="text-heading-small text-neutral-700">{name}</div>
        <div className="text-title-small text-neutral-700">
          {t("inspired_plan")}
        </div>
      </div>
      <div className="flex flex-col">
        <div>
          <span className="text-primaryBlue text-body-large">{kcal}</span>
          <span className="text-neutral-700 text-body-medium">
            {" "}
            {t("kcal")}
          </span>
        </div>

        <div className="text-neutral-700 text-title-medium">
          {t("days", { days: days })}
        </div>
      </div>
      <div className="flex gap-2 items-center flex-wrap">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="px-2 py-1.5 bg-white rounded-full border border-neutral-300 justify-center items-center flex text-neutral-700 text-body-medium"
          >
            {tag}
          </div>
        ))}
      </div>

      <div className="h-[2px] w-full bg-gradient-to-r from-primaryLight via-primary to-primaryLight" />

      <div className="grid grid-cols-2 gap-4">
        {items.map((item, index) => (
          <div key={index} className="flex items-center gap-2">
            <img src={primaryTick} alt="primaryTick" className="w-4" />
            <span className="text-label-large">{item}</span>
          </div>
        ))}
      </div>
      <div className="h-[2px] w-full bg-gradient-to-r from-primaryLight via-primary to-primaryLight" />
      <PrimaryButton
        marginTop=""
        text={t("view_plan")}
        onClick={() => {
          navigate(`/star-inspired-plans/${name}`);
        }}
        showIcon={false}
      />
    </div>
  );
};

export default StarInspiredPlanCard;
