import BaseLayout from "../layout/BaseLayout";
import { useTranslation } from "react-i18next";
import membership from "../assets/images/backgrounds/membership.png";
import logoLight from "../assets/images/logos/logoLight.svg";
import goldMembership from "../assets/images/logos/goldMembership.png";
import flare from "../assets/images/backgrounds/flare.png";
import contact from "../assets/images/backgrounds/contact.png";
import verified from "../assets/images/icons/verified.svg";
import infinity from "../assets/images/icons/infinity.svg";
import { Collapse, message, Skeleton } from "antd";
import { useEffect, useRef, useState } from "react";
import { getConfig, getProducts } from "../dataManager/Settings";
import {
  formatDate,
  formattedNumber,
  getGoldMembership,
  isPaymentsAllowed,
  logSentryError,
  openExternalLink,
} from "../utilities/helper";
import PrimaryButton from "../subComponents/PrimaryButton";
import SecondaryButton from "../subComponents/SecondaryButton";
import { useNavigate } from "react-router-dom";
import { withStore } from "../hocs/withStore";
import RequireAuth from "../hocs/RequireAuth";
import LoginDrawer from "../components/LoginDrawer";
import usePayments from "../hooks/usePayments";
import KioskLoader from "../components/kiosk/loader";

const MembershipPage = ({ store }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [activeKey, setActiveKey] = useState();
  const [isGoldMember, setIsGoldMember] = useState(false);
  const [goldMembershipData, setGoldMembershipData] = useState({});
  const [userMembershipData, setUserMembershipData] = useState({});
  const [goldMembershipFaqs, setGoldMembershipFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [faqLoading, setFaqLoading] = useState(false);
  const [openLoginDrawer, setOpenLoginDrawer] = useState(false);
  const divRef = useRef(null);
  const parentDivRef = useRef(null);
  const interval = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight - 10 && rect.top > 10);
      }
    };

    if (parentDivRef.current) {
      parentDivRef.current.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (parentDivRef.current) {
        parentDivRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const getUserProducts = (callback) => {
    setUserLoading(true);
    getProducts({ status: "SUCCESS" })
      .then((res) => {
        callback(res);
      })
      .catch((err) => {
        logSentryError(err);
      })
      .finally(() => {
        setUserLoading(false);
      });
  };

  useEffect(() => {
    getUserProducts((res) => {
      const goldMembership = getGoldMembership(res);
      setIsGoldMember(goldMembership ? true : false);
      setUserMembershipData(goldMembership);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getConfig("PRODUCT_SKU_MAPPING")
      .then((res) => {
        setGoldMembershipData(res?.data?.data?.config?.gold_membership ?? {});
      })
      .catch((err) => {
        logSentryError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const onInitiatePayment = () => {
    if (isPaymentsAllowed()) {
      setPaymentLoading(true);
      initiatePayment();
    } else {
      messageApi.error(t("Payment gateway not available!"));
    }
  };

  const {
    initiatePayment,
    isAlreadyPurchased,
    isPolling,
    orderDetails,
    billingData,
  } = usePayments({
    productName: goldMembershipData.code,
    onSuccess: (data) => {
      if (setPaymentLoading) {
        setPaymentLoading(false);
      }
      navigate(`/payment-status?status=success&redirect=/membership`);
    },
    onSuccessEvent: () => {
      setPaymentProcessing(true);
    },
    onError: (error) => {
      navigate(`/payment-status?status=error&redirect=/membership`);
    },
    onStatusUpdate: (status) => {},
    onCancel: () => {
      navigate(`/payment-status?status=cancelled&redirect=/membership`);
    },
    onPending: () => {
      setPaymentProcessing(true);
    },
    onInvalidData: (data) => {
      navigate(`/payment-status?status=invalid&redirect=/membership`, {
        state: {
          data: data,
        },
      });
    },
    onBackendError: (message) => {
      setPaymentLoading(false);
      setPaymentProcessing(false);
      messageApi.error(message ?? t("something_went_wrong"));
    },
    autoCheckForExistingPurchase: false,
  });

  useEffect(() => {
    setFaqLoading(true);
    getConfig("GOLD_MEMBERSHIP_FAQS")
      .then((res) => {
        setGoldMembershipFaqs(res?.data?.data?.config ?? []);
      })
      .catch((err) => {
        logSentryError(err);
      })
      .finally(() => {
        setFaqLoading(false);
      });
  }, []);

  const onSuccess = () => {
    getUserProducts((res) => {
      setOpenLoginDrawer(false);
      const goldMembership = getGoldMembership(res);
      if (goldMembership) {
        setIsGoldMember(true);
        setUserMembershipData(goldMembership);
      } else {
        onInitiatePayment();
      }
    });
  };

  const getBuyNowButton = (width = "w-full") => {
    return (
      <PrimaryButton
        marginTop=""
        text={t("buy_now")}
        showIcon={false}
        onClick={() =>
          store?.data?.user_type === "registered"
            ? onInitiatePayment()
            : setOpenLoginDrawer(true)
        }
        width={width}
      />
    );
  };

  return (
    <>
      {contextHolder}
      {paymentLoading ? (
        <KioskLoader
          title={
            paymentProcessing
              ? t("processing_your_payment")
              : t("initiating_your_payment")
          }
          description={`${t("please_wait")}`}
        />
      ) : (
        <BaseLayout title={t("membership")}>
          <div
            className="flex flex-col gap-6 p-6 overflow-y-scroll"
            ref={parentDivRef}
          >
            {loading || userLoading ? (
              <Skeleton active />
            ) : (
              <>
                <div className="p-[2px] rounded-xl bg-gold-border">
                  <div
                    className="w-full flex flex-col gap-2 rounded-xl py-5"
                    style={{
                      backgroundImage: `url(${membership})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="w-full flex justify-between items-center">
                      {isGoldMember ? (
                        <div className="text-white text-headline-small pl-6">
                          {t("gold_member")}
                        </div>
                      ) : (
                        <div
                          className="h-10 flex items-center justify-center px-6"
                          style={{
                            backgroundImage: `url(${flare})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                          }}
                        >
                          <img
                            className="h-6"
                            src={goldMembership}
                            alt="logo"
                          />
                        </div>
                      )}
                      <img className="h-10 px-6" src={logoLight} alt="logo" />
                    </div>
                    <div className="text-white text-label-large px-6">
                      {isGoldMember
                        ? t("gold_member_heading")
                        : t("buy_gold_membership_heading")}
                    </div>
                    <div className="px-6 flex justify-between items-center">
                      <div className="text-white text-title-medium">
                        {isGoldMember
                          ? t("expiry_date")
                          : t("days", {
                              days: goldMembershipData.expiryInDays,
                            })}
                      </div>
                      <div className="text-white text-title-large flex justify-center items-center gap-1">
                        {isGoldMember && userMembershipData?.expiryAt ? (
                          formatDate(userMembershipData.expiryAt, "DD MMM 'YY")
                        ) : (
                          <>
                            <div className="text-white/60 line-through">
                              {goldMembershipData.currencySymbol}
                              {goldMembershipData.actualPrice}
                            </div>
                            <div>
                              {goldMembershipData.currencySymbol}
                              {goldMembershipData.discountedPrice}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="text-neutral-700 text-body-large flex items-center gap-1">
                    {t("gold_privileges")}
                    <img src={verified} alt="verified" />
                  </div>
                  <div className="flex flex-col gap-2 bg-white rounded-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] p-3 ">
                    <div className="grid grid-cols-4 gap-2">
                      <div className="col-span-2 text-neutral-700 text-body-medium">
                        {t("benefits")}
                      </div>
                      <div className="text-center text-neutral-700 text-body-medium">
                        {t("gold")}
                      </div>
                      <div className="text-center text-neutral-700 text-body-medium">
                        {t("free")}
                      </div>
                    </div>
                    {goldMembershipData?.featurePriviliges?.map(
                      (item, index) => (
                        <>
                          <div
                            className="h-[1px] bg-gray-300"
                            key={`${index}-divider`}
                          />
                          <div
                            className="grid grid-cols-4 gap-2"
                            key={`${index}-data`}
                          >
                            <div className="col-span-2 text-neutral-400 text-body-medium">
                              {t(item.name)}
                            </div>
                            <div className="flex justify-center items-center">
                              <img
                                className="flex-none h-4"
                                src={infinity}
                                alt="infinity"
                              />
                            </div>
                            <div className="text-center text-neutral-400 text-body-medium">
                              {t("limited")}
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </div>
                </div>
                {(faqLoading || goldMembershipFaqs.length > 0) && (
                  <div className="flex flex-col gap-4">
                    <div className="text-neutral-700 text-body-large flex items-center gap-1">
                      {t("most_asked_questions")}
                    </div>
                    {faqLoading ? (
                      <Skeleton active />
                    ) : (
                      goldMembershipFaqs.map((item, index) => (
                        <Collapse
                          key={index}
                          collapsible="header"
                          accordion={true}
                          activeKey={[activeKey]}
                          onChange={(key) => setActiveKey(key)}
                          expandIconPosition="end"
                          items={[
                            {
                              key: index,
                              label: (
                                <div className="text-neutral-700 text-body-medium">
                                  {item.title}
                                </div>
                              ),
                              children: (
                                <div className="flex flex-col gap-4">
                                  <div className="text-neutral-700 text-body-medium">
                                    {item.description}
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                        />
                      ))
                    )}
                  </div>
                )}
                <div
                  className="flex flex-col gap-4 rounded-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] p-4"
                  style={{
                    backgroundImage: `url(${contact})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => {
                    openExternalLink("mailto:hello@jivi.ai", "_blank");
                  }}
                >
                  <div className="opacity-80 text-orange-500 text-body-large">
                    {t("contact_support")}
                  </div>
                  <div className="opacity-80 text-zinc-400 text-body-medium">
                    {t("write_to_us", { email: "hello@jivi.ai" })}
                  </div>
                </div>
                {isGoldMember ? (
                  <SecondaryButton
                    width="w-full"
                    customClass=""
                    paddingY="py-4"
                    text={t("home")}
                    showIcon={false}
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                ) : (
                  <div className="flex flex-col gap-2" ref={divRef}>
                    {getBuyNowButton()}
                    <SecondaryButton
                      width="w-full"
                      customClass=""
                      paddingY="py-4"
                      text={t("may_be_later")}
                      showIcon={false}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </div>
                )}
                <div className="text-center text-body-small">
                  <span className="text-zinc-500">
                    {t("for_more_information")}&nbsp;
                  </span>
                  <span
                    className="text-blue-500"
                    onClick={() => {
                      openExternalLink(
                        "https://www.jivi.ai/terms-conditions/",
                        "_blank"
                      );
                    }}
                  >
                    {t("terms_and_conditions")}
                  </span>
                  <span className="text-zinc-500">&nbsp;{t("and")}&nbsp;</span>
                  <span
                    className="text-blue-500"
                    onClick={() => {
                      openExternalLink(
                        "https://www.jivi.ai/privacy-policy",
                        "_blank"
                      );
                    }}
                  >
                    {t("privacy_policy")}
                  </span>
                </div>
                {!isVisible && !isGoldMember && (
                  <div className="bg-gradient-to-b from-primaryLight to-white rounded-t-2xl absolute bottom-0 left-0 w-full p-4 flex justify-between items-center">
                    <div className="flex flex-col">
                      <div className="text-neutral-600 text-body-medium">
                        {t("introductory_offer")}
                      </div>
                      <div className="text-blue-500 text-title-large">
                        {t("off", {
                          percentage: formattedNumber(
                            ((goldMembershipData.actualPrice -
                              goldMembershipData.discountedPrice) /
                              goldMembershipData.actualPrice) *
                              100
                          ),
                        })}
                      </div>
                    </div>
                    {getBuyNowButton("w-fit")}
                  </div>
                )}
              </>
            )}
          </div>
          {openLoginDrawer && (
            <LoginDrawer
              open={openLoginDrawer}
              onClose={() => setOpenLoginDrawer(false)}
              onSuccess={onSuccess}
            />
          )}
        </BaseLayout>
      )}
    </>
  );
};

export default withStore(RequireAuth(MembershipPage));
