import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import mixpanel from "mixpanel-browser";
import clevertap from "clevertap-web-sdk";
import * as Sentry from "@sentry/react";
import { axiosInstance } from "./configureAxios";
import { axiosInstanceDs } from "./configureAxiosDs";
import { axiosInstanceDsJavris } from "./configureAxiosDsJarvis";

dayjs.extend(utc);
dayjs.extend(timezone);

export const isProduction = () => {
  return import.meta.env.VITE_ENVIRONMENT == "production";
};

export const isDebug = () => {
  return import.meta.env.VITE_ENVIRONMENT == "debug";
};

export const logSentryError = (error) => {
  if (isProduction()) {
    Sentry.captureException(error);
  }
};

export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const getMockValue = () => {
  try {
    return JSON.parse(localStorage.getItem("mock"));
  } catch (e) {
    logSentryError(e);
    return false;
  }
};

export const base64ToBlob = (base64, contentType) => {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
    const slice = byteCharacters.slice(offset, offset + 512);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const blobToBase64 = (blob, callback) => {
  const reader = new FileReader();
  reader.onload = function () {
    const base64String = reader.result;
    callback(base64String);
  };
  reader.onerror = function (error) {
    logSentryError(error);
  };
  reader.readAsDataURL(blob);
};

export const getLimitedText = (text, characters) => {
  if (text.length <= characters) {
    return text;
  } else {
    return text.substring(0, characters) + "...";
  }
};

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof File || file instanceof Blob)) {
      reject("The provided value is not a File or Blob");
      return;
    }
    const reader = new FileReader();
    reader.onload = function (event) {
      resolve(event.target.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const generateRandomString = (length = 5) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatDate = (
  dateString,
  format = "MMM D 'YY | h:mm a",
  timezone = "Asia/Kolkata"
) => {
  return dayjs(dateString).tz(timezone).format(format);
};

export const titleCase = (s) => {
  return s
    .toLowerCase()
    .replace(/^_*(.)|_+(.)/g, (s, c, d) =>
      c ? c.toUpperCase() : " " + d.toUpperCase()
    );
};
export const snakeCase = (str) => {
  return str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "_")
    .replace(/[^a-z0-9_]/g, "");
};

export const kebabToCamelCase = (str) => {
  return str
    .toLowerCase()
    .split("-")
    .map((word, index) => {
      if (index === 0) {
        return word;
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("");
};

export const getFormattedTime = (timeElapsed) => {
  const seconds = Math.floor(timeElapsed / 10);
  const hundredths = timeElapsed % 10;
  return `${seconds < 10 ? `0${seconds}` : seconds}:${hundredths}0`;
};

export const generateRandomNumber = (min = 30, max = 60) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const isAppleDevice = () => {
  return (
    /Mac|iPod|iPhone|iPad/.test(navigator.platform) ||
    (/MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 1)
  );
};

export const pause = (milliseconds = 1000) => {
  var dt = new Date();
  while (new Date() - dt <= milliseconds) {}
};

export const removeQueryStringParameter = (
  key,
  searchParams,
  setSearchParams
) => {
  const params = new URLSearchParams(searchParams);
  params.delete(key);
  setSearchParams(params);
};

export const checkMicrophonePermission = async (setMicPermission) => {
  setMicPermission("granted");
  // try {
  //   const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
  //   permissionStatus.onchange = (event) => {
  //     setMicPermission(event.target.state);
  //   };
  //   setMicPermission(permissionStatus.state)
  // } catch (error) {
  //   setMicPermission("denied");
  // }
};

export const appRating = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      // AndroidInterface.triggerInAppReview()
    } else {
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const requestAppPermission = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.requestAndroidPermissions();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "triggerIosPermission",
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const updateApp = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.checkForUpdates("IMMEDIATE", 0, false);
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const sendDataToInterface = (data) => {
  try {
    if (
      typeof AndroidInterface !== typeof undefined &&
      AndroidInterface.userDetails
    ) {
      if (AndroidInterface.syncAnalyticsUser) {
        AndroidInterface.userDetails(
          data.name,
          data.userId,
          data.mobile,
          data.token,
          data.countryCode
        );
      } else {
        AndroidInterface.userDetails(
          data.name,
          data.userId,
          data.mobile,
          data.token
        );
      }
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "userDetails",
        data: {
          name: data.name,
          userId: data.userId,
          mobile: data.mobile,
          token: data.token,
          countryCode: data.countryCode,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const interfaceLogout = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.logout();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "logout",
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const splitMessagesByLastAi = (messages) => {
  let lastAiIndex = -1;
  for (let i = messages.length - 1; i >= 0; i--) {
    if (messages[i].type === "ai") {
      lastAiIndex = i;
      break;
    }
  }
  const result = {
    before: [],
    after: [],
  };
  if (lastAiIndex !== -1) {
    result.before = messages.slice(0, lastAiIndex);
    result.after = messages.slice(lastAiIndex);
  } else {
    result.before = messages;
  }

  return result;
};

export const getAgeFromDateOfBirth = (dob) => {
  if (!dob) return 0;
  const today = new Date();
  const [year, month, day] = dob.split("-");
  const birthDate = new Date(year, month - 1, day);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const isAndroid = () => {
  return /Android/i.test(navigator.userAgent);
};

export const isIOSDevice = () => {
  return !!isIOS;
};

export const isIosApp = () => {
  return !!window?.webkit?.messageHandlers?.iosInterface?.postMessage;
};

export const isWebDevice = () => {
  return !isMobile;
};

export const isWebkitAvailable = () => {
  return !isAndroid() && "webkitSpeechRecognition" in window;
};

export const isAnonymous = () => {
  return (
    JSON.parse(localStorage.getItem("data") ?? "{}")?.user_type === "anonymous"
  );
};

export const isTata1mgAllowed = () => {
  return isIOS || isSafari || isAnonymous();
};

export const isTrackingAllowed = (key) => {
  return import.meta.env.VITE_TRACKING_APPS.toLowerCase()
    .split(",")
    .includes(key);
};

export const handleInApp = (method, data) => {
  if (
    typeof AndroidInterface !== typeof undefined &&
    AndroidInterface[method]
  ) {
    AndroidInterface[method](
      JSON.stringify(data),
      JSON.stringify({
        isMixpanelAllowed: isTrackingAllowed("mixpanel"),
        isClevertapAllowed: isTrackingAllowed("clevertap"),
      })
    );
    return true;
  } else if (window.webkit?.messageHandlers?.iosInterface?.postMessage) {
    window.webkit.messageHandlers.iosInterface.postMessage({
      action: method,
      data: data,
      isAnalyticsAllowed: {
        isMixpanelAllowed: isTrackingAllowed("mixpanel"),
        isClevertapAllowed: isTrackingAllowed("clevertap"),
      },
    });
    return true;
  }
  return false;
};

export const analyticsAppIdentify = (userId, userData) => {
  if (isProduction() || isDebug()) {
    if (
      !handleInApp("syncAnalyticsUser", {
        userId,
        userData,
      })
    ) {
      if (isTrackingAllowed("mixpanel")) {
        mixpanel.identify(userId);
        mixpanel.people.set({
          $name: userData.name,
          mobile: userData.mobile,
          token: userData.token,
        });
      }

      if (isTrackingAllowed("clevertap")) {
        clevertap.onUserLogin.push({
          Site: {
            Name: userData.name,
            Identity: userId,
            Phone: userData.countryCode + userData.mobile,
            Token: userData.token,
          },
        });
      }
    }
  }
};

export const analyticsAppTrack = (eventName, eventData = {}) => {
  if (isProduction() || isDebug()) {
    if (
      !handleInApp("analyticsAppTrackEvent", {
        eventName,
        eventData,
      })
    ) {
      if (isTrackingAllowed("mixpanel")) {
        mixpanel.track(eventName, eventData);
      }

      if (isTrackingAllowed("clevertap")) {
        clevertap.event.push(eventName, eventData);
      }
    }
  } else {
    console.log(eventName, eventData);
  }
};

export const countWords = (str) => {
  const words = str
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0);
  return words.length;
};

export const convertToS3Uri = (s3Url) => {
  const regex = /^https:\/\/(.+?)\.s3\.amazonaws\.com\/(.+)$/;

  const match = s3Url.match(regex);

  if (match) {
    const bucketName = match[1];
    const objectKey = match[2];
    return `s3://${bucketName}/${objectKey}`;
  } else {
    throw new Error("Invalid S3 URL");
  }
};

export const openNativeShareModal = (content, url) => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.shareLink(content, url);
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const isAppInstalled = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      return true;
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    logSentryError(e);
    return true;
  }
};

export const connectHealthApp = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.connectHealthConnect(
        dayjs().subtract(1, "day").startOf("day").format(),
        dayjs().format(),
        120
      );
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "connectHealthConnect",
        data: {
          startTime: dayjs().subtract(1, "day").startOf("day").format(),
          endTime: dayjs().format(),
          interval: 120,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const syncHealthApp = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.syncHealthConnectData(
        dayjs().subtract(1, "day").startOf("day").format(),
        dayjs().format(),
        120
      );
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "syncHealthConnectData",
        data: {
          startTime: dayjs().subtract(1, "day").startOf("day").format(),
          endTime: dayjs().format(),
          interval: 120,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const isHealthConnectConnected = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      AndroidInterface.checkHealthConnectStatus();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "checkHealthConnectStatus",
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const triggerAppBackButton = () => {
  try {
    if (typeof AndroidInterface !== typeof undefined) {
      if (AndroidInterface.deeplinkBackButton) {
        AndroidInterface.deeplinkBackButton();
      }
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const convertTo12HourFormat = (timestamp, aggType = "SEC") => {
  if (!timestamp) return "-";
  const localTime = dayjs(timestamp);
  switch (aggType) {
    case "SEC":
      return localTime.format("hh:mm A");
    default:
      return localTime.format("DD/MM/YYYY");
  }
};

export const getLocalTime = (timestamp) => {
  return dayjs.utc(timestamp).local();
};

export const getLatestSmartHealthValue = (data) => {
  if (data.length == 0) {
    return {
      timestamp: null,
      value: null,
    };
  }
  return data.reduce((latest, current) => {
    return new Date(current.timestamp) > new Date(latest.timestamp)
      ? current
      : latest;
  });
};

export const getTotalSmartHealthValue = (data) => {
  let total = 0;
  data.map((entry) => {
    total += entry.value;
  });
  return total;
};

export const formattedNumber = (value, precision = 0, defaultValue = "-") => {
  if (
    typeof value !== "number" ||
    isNaN(value) ||
    !Number.isInteger(precision) ||
    precision < 0
  ) {
    return defaultValue;
  }
  const multiplier = Math.pow(10, precision);
  return Math.round(value * multiplier) / multiplier;
};

export const isHealthCheckDataAvailable = (data) => {
  let dataAvailable = false;
  Object.keys(data).every((key) => {
    if (
      data[key] &&
      ((Array.isArray(data[key]) && data[key].length > 0) ||
        Object.keys(data[key]).length > 0)
    ) {
      dataAvailable = true;
      return false;
    } else {
      return true;
    }
  });
  return dataAvailable;
};

export const divideNumber = (value, divisor, precision) => {
  if (typeof value !== "number" || isNaN(value)) {
    return value;
  }
  return formattedNumber(value / divisor, precision);
};

export const getAggType = (fromDate, toDate) => {
  const diff = toDate.diff(fromDate, "day");
  if (diff > 90) {
    return "MONTHLY";
  }
  if (diff > 29) {
    return "WEEK";
  }
  if (diff > 1) {
    return "DAY";
  }
  return "SEC";
};

export const getGradientColors = (percentage, value) => {
  if ((value && value.toLowerCase() == "high") || percentage > 60) {
    return {
      gradientStart: "#ef4444",
      gradientEnd: "#f7adad",
      textColor: "#ef4444",
      percentageTextColor: "#ef4444",
    };
  }
  if ((value && value.toLowerCase() == "normal") || percentage > 30) {
    return {
      gradientStart: "#ffc700",
      gradientEnd: "#fcecb9",
      textColor: "#ffc700",
      percentageTextColor: "#ffc700",
    };
  }
  return {
    gradientStart: "#009A51",
    gradientEnd: "#B0E0C9",
    textColor: "#009A51",
    percentageTextColor: "#009A51",
  };
};

export const fillMissingDates = (
  data,
  startTime,
  endTime,
  key = "value",
  getColors = false,
  extraData = {}
) => {
  let startDate = dayjs(startTime);
  let endDate = dayjs(endTime);

  let dateMap = new Map();
  data.forEach((entry) => {
    let date = dayjs(entry.timestamp);
    let formattedDate = date.format("YYYY-MM-DD");
    dateMap.set(formattedDate, entry.value);
  });

  let result = [];

  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    let formattedDate = startDate.format("DD/MM");
    let dateKey = startDate.format("YYYY-MM-DD");
    let value = dateMap.has(dateKey) ? dateMap.get(dateKey) : 0;

    let extra = {};
    if (getColors) {
      extra = getGradientColors(value);
    }
    result.push({ name: formattedDate, [key]: value, ...extra, ...extraData });

    startDate = startDate.add(1, "day");
  }
  return result;
};

export const startHeartRateMonitor = (url) => {
  try {
    if (
      typeof AndroidInterface !== typeof undefined &&
      AndroidInterface.startHeartRateMonitor
    ) {
      AndroidInterface.startHeartRateMonitor(url);
      return true;
    } else if (window.webkit?.messageHandlers?.iosInterface?.postMessage) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "startHeartRateMonitor",
      });
      return true;
    }
    return false;
  } catch (e) {
    logSentryError(e);
    return false;
  }
};

export const isAnalyticsAndroid = () => {
  return (
    typeof AndroidInterface !== typeof undefined &&
    AndroidInterface.syncAnalyticsUser
  );
};

export const getScoreData = (score, t) => {
  if (score <= 20)
    return {
      textColor: "text-green-500",
      tip: t("great_job_keep_enjoying_your_calm"),
      image: "image_0",
      background: "bg-scoreBg0",
      status: "low",
    };
  if (score <= 40)
    return {
      textColor: "text-lime-500",
      tip: t("feeling_good_maintain_your_relaxed_pace"),
      image: "image_1",
      background: "bg-scoreBg1",
      status: "low",
    };
  if (score <= 60)
    return {
      textColor: "text-yellow-500",
      tip: t("sensing_stress_take_deep_breaths_now"),
      image: "image_2",
      background: "bg-scoreBg2",
      status: "moderate",
    };
  if (score <= 80)
    return {
      textColor: "text-orange-500",
      tip: t("pause_a_moment_inhale_peace_exhale_stress"),
      image: "image_3",
      background: "bg-scoreBg3",
      status: "moderate",
    };
  if (score <= 90)
    return {
      textColor: "text-red-500",
      tip: t("youre_tense_sit_down_and_relax_a_few_minutes"),
      image: "image_4",
      background: "bg-scoreBg4",
      status: "high",
    };
  return {
    textColor: "text-red-700",
    tip: t("high_stress_detected_take_time_to_unwind_now"),
    image: "image_5",
    background: "bg-scoreBg5",
    status: "high",
  };
};

export const formatDataDateWise = (data, t) => {
  const today = dayjs();
  let unformattedData = {};

  const updatedData = [
    { name: t("today"), data: [] },
    { name: "", data: [] },
  ];

  data.forEach((entry) => {
    const createdAt = dayjs(entry.createdAt);
    const daysAgo = today.diff(createdAt, "day");
    unformattedData[daysAgo] = [...(unformattedData?.[daysAgo] ?? []), entry];
  });

  Object.keys(unformattedData).forEach((key) => {
    if (key == 0) {
      updatedData.push({ name: t("today"), data: unformattedData[key] });
    } else {
      updatedData.push({
        name: t("days_ago", { days: key }),
        data: unformattedData[key],
      });
    }
  });
  return updatedData;
};

export const formatCreatedAt = (
  createdAt,
  format = "YYYY-MM-DD HH:mm:ss",
  t
) => {
  const now = dayjs();
  const createdTime = dayjs(createdAt);
  const diffInSeconds = now.diff(createdTime, "second");
  const diffInMinutes = now.diff(createdTime, "minute");
  const diffInHours = now.diff(createdTime, "hour");

  if (diffInSeconds < 60) {
    return `${diffInSeconds} ${t("seconds_ago")}`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} ${t("minutes_ago")}`;
  } else if (diffInHours < 24) {
    return `${diffInHours} ${t("hours_ago")}`;
  } else {
    return createdTime.format(format);
  }
};

export const getQueryParams = () => {
  const params = {};
  const queryString = window.location.search;

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);

    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
    }
  }

  return params;
};

export const getDuration = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);

  const padZero = (num) => num.toString().padStart(2, "0");

  if (hours > 0) {
    return `${hours}:${padZero(minutes)}:${padZero(seconds)}`;
  }
  return `${minutes}:${padZero(seconds)}`;
};

export const getMinutes = (duration) => {
  return Math.floor(duration / 60);
};

export const getScreenWidth = () => {
  if (isAndroid()) {
    return window.innerHeight;
  }
  if (window.innerWidth > window.innerHeight) {
    return window.innerHeight;
  }
  return window.innerWidth;
};

export const getScreenHeight = () => {
  if (isAndroid()) {
    return window.innerWidth;
  }
  if (window.innerWidth > window.innerHeight) {
    return window.innerWidth;
  }
  return window.innerHeight;
};

export const isInternalUser = () => {
  let userRole = JSON.parse(localStorage.getItem("data"))?.user?.roles ?? [];
  return userRole.map((role) => role.name).includes("INTERNAL_USER");
};

export const informNativeApp = (language) => {
  try {
    // if (
    //   typeof AndroidInterface !== typeof undefined &&
    //   AndroidInterface.changeLanguage
    // ) {
    //   AndroidInterface.changeLanguage(language);
    //   return true;
    // } else
    if (window.webkit?.messageHandlers?.iosInterface?.postMessage) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "changeLanguage",
        data: {
          language: language,
        },
      });
      return true;
    }
    return false;
  } catch (e) {
    logSentryError(e);
    return false;
  }
};

export const getNameForKey = (key, options) => {
  const found = options.find((option) => option.key === key);
  return found ? found.name : key;
};

export const openExternalLink = (url, type = "_blank") => {
  try {
    if (
      typeof AndroidInterface !== typeof undefined &&
      AndroidInterface.openUrlInBrowser
    ) {
      AndroidInterface.openUrlInBrowser(url);
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "openUrlAppBrowser",
        data: {
          url: url,
        },
      });
    } else {
      window.open(url, type);
    }
  } catch (e) {
    logSentryError(e);
    window.open(url, type);
  }
};

export const handleWidth = (url) => {
  if (url.includes("?")) {
    return url + "&w=500";
  }
  return url + "?w=500";
};

export const isKiosk = () => {
  const kioskUrls = import.meta.env.VITE_KIOSK_URLS ?? "";
  return kioskUrls.split(",").includes(window.location.host);
};

export const allExceptLast = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return [];
  }
  return arr.slice(0, -1);
};

export const isKioskViewOnly = () => {
  return import.meta.env.VITE_KIOSK_VIEW_ONLY == "true";
};

export const setHeaders = (key, value) => {
  axiosInstance.defaults.headers.common[key] = value;
  axiosInstanceDs.defaults.headers.common[key] = value;
  axiosInstanceDsJavris.defaults.headers.common[key] = value;
};

export const getLocation = () => {
  try {
    if (
      typeof AndroidInterface !== typeof undefined &&
      AndroidInterface.sendLocationToWebApp
    ) {
      AndroidInterface.sendLocationToWebApp();
    } else if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "sendLocation",
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const isPaymentsAllowed = () => {
  return (
    (typeof AndroidBillingInterface !== typeof undefined &&
      typeof AndroidBillingInterface.launchPurchase !== typeof undefined) ||
    getIfInterfaceExists("sendAllInterface")
  );
};

export const isJson = (item) => {
  let value = typeof item !== "string" ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === "object" && value !== null;
};

export const getIosInterfaceFromDevice = () => {
  if (window.webkit?.messageHandlers?.iosInterface) {
    window.webkit.messageHandlers.iosInterface.postMessage({
      action: "sendAllInterface",
    });
  }
};

export const getIosInterface = () => {
  return JSON.parse(localStorage.getItem("allIosInterface") ?? "[]");
};

export const getIfInterfaceExists = (interfaceName) => {
  const allInterfaces = getIosInterface();
  return allInterfaces.includes(interfaceName);
};

export const getGoldMembership = (res) => {
  return res?.data?.data?.plans.find(
    (plan) =>
      plan.status === "SUCCESS" &&
      plan.planDetails?.skuId === "jivi_membership_gold_3_months"
  );
};
