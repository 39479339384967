import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import RequireAuth from "../hocs/RequireAuth";
import BaseLayout from "../layout/BaseLayout";
import { message } from "antd";
import selena from "../assets/images/nutritionPlans/selena.png";
import { useEffect, useRef, useState } from "react";
import goldenLock from "../assets/images/icons/goldenLock.svg";
import water from "../assets/images/icons/water.svg";
import meals from "../assets/images/icons/meal.svg";
import exercise from "../assets/images/icons/exercise.svg";
import primaryTick from "../assets/images/icons/primaryTick.svg";
import MealWithDishCard from "../subComponents/MealWithDishCard";
import PrimaryButton from "../subComponents/PrimaryButton";
import SecondaryButton from "../subComponents/SecondaryButton";

const StarInspiredPlanDetails = () => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { name } = useParams();
  const [data, setData] = useState({
    image: selena,
    name: "Selena Gomez",
    days: 30,
    kcal: 1300,
    tags: ["Low-Carb", "Balanced", "Vegetarian", "High Protein"],
    items: ["7 days a week", "30 days", "5 meals a day", "Tasty dishes"],
  });
  const [isOnTop, setIsOnTop] = useState(false);
  const divRef = useRef(null);
  const scrollRef = useRef(null);

  const handleGetPosition = () => {
    if (divRef && divRef.current) {
      const rect = divRef.current.getBoundingClientRect();
      setIsOnTop(rect.top < 0);
    }
  };

  useEffect(() => {
    if (scrollRef && scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleGetPosition);
    }
    return () => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleGetPosition);
      }
    };
  }, []);

  return (
    <BaseLayout
      title={name}
      isSticky={true}
      {...(isOnTop
        ? {
            headerBackgroundColor: "bg-white",
          }
        : { headerBackgroundColor: "bg-transparent" })}
    >
      {contextHolder}
      <div
        className="w-full h-full overflow-scroll flex flex-col gap-4"
        style={{
          backgroundImage: `linear-gradient(to bottom left, rgba(255, 255, 255, 0.7), white, white), url(${data.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
        ref={scrollRef}
      >
        <div className="mt-[60px]" ref={divRef} />
        <div className="w-full rounded-xl flex flex-col gap-6 px-6">
          <div className="w-fit px-4 py-3 bg-white rounded-2xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] border border-blue-500/10 flex flex-col justify-start items-start gap-2.5">
            <div className="text-black/75 text-body-medium">
              {t("target_calories")}
            </div>
            <div>
              <span className="text-blue-500 text-heading-large-prominent">
                {data.kcal}
              </span>
              <span className="text-black/60 text-body-large">
                &nbsp;{t("kcal")}
              </span>
            </div>
          </div>

          <div className="flex gap-2 items-center flex-wrap">
            {data.tags.map((tag, index) => (
              <div
                key={index}
                className="px-2 py-1.5 bg-white rounded-full border border-neutral-300 justify-center items-center flex text-neutral-700 text-body-medium"
              >
                {tag}
              </div>
            ))}
          </div>

          <div className="h-[1px] w-full bg-gradient-to-r from-primaryLight via-primary to-primaryLight rounded-full" />

          <div
            className="rounded-2xl p-4 flex flex-col gap-4"
            style={{
              background:
                "radial-gradient(81.27% 83.64% at 82.57% 16.36%, rgba(176, 233, 239, 0.42) 0%, rgba(105, 170, 252, 0.4) 100%)",
            }}
          >
            <div className="text-neutral-700 text-body-large">
              {t("what_you_get")}
            </div>
            <div className="rounded-2xl p-4 bg-white grid grid-cols-2 gap-4">
              {data.items.map((item, index) => (
                <div key={index} className="flex items-center gap-2">
                  <img src={primaryTick} alt="primaryTick" className="w-4" />
                  <span className="text-label-large">{item}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="shadow-md bg-gradient-to-b from-transparent to-primaryLight w-full rounded-b-xl flex justify-center items-center gap-4 p-4">
          {[1, 2, 3, 4, 5, 6, 7].map((day, index) => (
            <div
              key={index}
              className="px-1.5 py-5 bg-white rounded-full shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] text-center text-orange-500 text-body-medium relative"
            >
              {t("day", { day })}
              {index !== 0 && (
                <div className="absolute top-0 left-0 h-full w-full rounded-full bg-neutral-700/60 backdrop-blur-sm flex justify-center items-center">
                  <img src={goldenLock} alt="goldenLock" className="w-4" />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="w-full flex justify-start items-center gap-6 px-6 py-3">
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="w-14 h-14 relative bg-white border border-primary rounded-full shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] p-4">
              <img src={meals} alt="meals" className="w-full h-full" />
            </div>
            <div className="text-title-medium-prominent">{t("meals")}</div>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center disabled-icon">
            <div className="w-14 h-14 relative bg-white rounded-full shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] p-4">
              <img src={water} alt="water" className="w-full h-full" />
            </div>
            <div className="text-title-medium">{t("water")}</div>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center disabled-icon">
            <div className="w-14 h-14 relative bg-white rounded-full shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] p-4">
              <img src={exercise} alt="exercise" className="w-full h-full" />
            </div>
            <div className="text-title-medium">{t("exercise")}</div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-6 px-6 pb-6">
          <MealWithDishCard
            type="breakfast"
            name="Nihal"
            calories={100}
            time="20 mins"
            image={selena}
            showLogButton={true}
          />
          <MealWithDishCard
            type="lunch"
            name="Nihal"
            calories={100}
            time="20 mins"
            image={selena}
          />
          <MealWithDishCard
            type="dinner"
            name="Nihal"
            calories={100}
            time="20 mins"
            image={selena}
          />
        </div>
      </div>
      <div className="w-full flex flex-col justify-center items-center p-6">
        <PrimaryButton
          text={t("personalise")}
          onClick={() => navigate("/star-inspired-plan")}
          marginTop=""
          customClass="px-4 border border-primary"
          paddingY="py-2"
          showIcon={false}
        />
        <SecondaryButton
          width="w-full"
          text={t("follow_plan")}
          onClick={() => navigate("/star-inspired-plan")}
        />
      </div>
    </BaseLayout>
  );
};

export default RequireAuth(StarInspiredPlanDetails);
