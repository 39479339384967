import { Drawer, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import countryCodes from "../assets/json/countryCodes.json";
import countryCodeRegexes from "../assets/json/countryCodeRegexes";
import DropdownField from "../Elements/DropdownField";
import InputField from "../Elements/InputField";
import mobile from "../assets/images/login/mobile.svg";
import PrimaryButton from "../subComponents/PrimaryButton";
import { getOtp, submitOtp } from "../dataManager/Auth";
import { analyticsAppTrack, logSentryError } from "../utilities/helper";
import OtpInput from "../Elements/OtpInput";
import { withStore } from "../hocs/withStore";

const LoginDrawer = ({ open, onClose, store, onSuccess = () => {} }) => {
  const { t } = useTranslation();
  const [isVerify, setIsVerify] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState(null);
  const [countryCode, setCountryCode] = useState("+91");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const onNext = () => {
    if (!loading) {
      if (mobileNumber.match(countryCodeRegexes[countryCode])) {
        setLoading(true);
        const body = {
          countryCode: `${countryCode}`,
          mobile: `${mobileNumber}`,
        };
        analyticsAppTrack("OTP Requested", body);
        getOtp(body)
          .then((res) => {
            setIsVerify(true);
          })
          .catch((error) => {
            logSentryError(error);
            messageApi.error(t("something_went_wrong"));
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setMobileNumberError(t("please_enter_valid_mobile_number"));
      }
    }
  };

  const onVerifyOtp = () => {
    if (!loading) {
      if (otp && otp.trim().length == 6) {
        setLoading(true);
        const body = {
          mobile: mobileNumber,
          countryCode: `${countryCode.replace(" ", "+")}`,
          otp: otp,
        };

        if (store.data?.user_type == "anonymous") {
          body["anonymousUserToken"] = store.data?.token;
        }

        analyticsAppTrack("OTP Verified", {
          mobile: mobileNumber,
          countryCode: `${countryCode.replace(" ", "+")}`,
        });
        submitOtp(body)
          .then((response) => {
            setLoading(false);
            store.setData({
              ...response.data.data,
              user_type: "registered",
            });
            onSuccess();
          })
          .catch((error) => {
            logSentryError(error);
            setLoading(false);
            let errorMessage = error?.response?.data?.error?.message;
            if (errorMessage) {
              setOtpError(errorMessage);
            } else {
              messageApi.error(t("something_went_wrong"));
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setOtpError(t("please_enter_valid_otp"));
      }
    }
  };

  return (
    <Drawer
      destroyOnClose={true}
      placement={"bottom"}
      onClose={onClose}
      open={open}
      className="w-full rounded-t-3xl"
      title={
        <div className="w-full flex justify-between items-center">
          {t("login")}
        </div>
      }
    >
      {contextHolder}
      <div className="h-full w-full flex flex-col gap-4">
        {isVerify ? (
          <div className="flex w-full flex-col gap-2">
            <OtpInput
              error={otpError}
              value={otp}
              onChange={(val) => {
                setOtpError();
                setOtp(val);
              }}
              length={6}
              onGo={onVerifyOtp}
            />
            {otpError && (
              <div className="w-full text-errorText text-title-small">
                {otpError}
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center gap-4">
            <DropdownField
              value={countryCode}
              setValue={(val) => {
                setCountryCode(val);
              }}
              optionsData={countryCodes}
              label={"Country Code"}
              onChange={(val) => {}}
            />
            <div className="w-full flex flex-col gap-2">
              <InputField
                value={mobileNumber}
                type="number"
                setValue={(val) => {
                  if (val.length <= 10) {
                    setMobileNumber(val);
                    setMobileNumberError();
                  }
                }}
                label={t("enter_mobile_number")}
                endIcon={
                  <div className="w-10 h-10 p-2 justify-center items-center gap-2.5 inline-flex">
                    <img src={mobile} className="w-6 h-6" />
                  </div>
                }
                error={mobileNumberError}
                onGo={onNext}
              />
              {mobileNumberError && (
                <div className="w-full text-errorText text-body-medium font-medium leading-tight">
                  {mobileNumberError}
                </div>
              )}
            </div>
          </div>
        )}
        <PrimaryButton
          text={isVerify ? t("submit") : t("next")}
          showIcon={false}
          onClick={isVerify ? onVerifyOtp : onNext}
          loading={loading}
        />
      </div>
    </Drawer>
  );
};

export default withStore(LoginDrawer);
