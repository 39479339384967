import { useTranslation } from "react-i18next";
import dinner from "../assets/images/icons/dinner.svg";
import lunch from "../assets/images/icons/lunch.svg";
import breakfast from "../assets/images/icons/breakfast.svg";
import SecondaryButton from "./SecondaryButton";

const MealWithDishCard = ({
  type,
  name,
  calories,
  time,
  image,
  logged = false,
  showLogButton = false,
}) => {
  const { t } = useTranslation();

  const getTypeDetails = (type) => {
    switch (type) {
      case "dinner":
        return { icon: dinner, title: t("dinner"), time: "20:00" };
      case "lunch":
        return { icon: lunch, title: t("lunch"), time: "14:00" };
      case "breakfast":
        return { icon: breakfast, title: t("breakfast"), time: "08:00" };
    }
  };

  return (
    <div
      className={`p-4 rounded-lg shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] bg-gradient-to-r ${logged ? "from-emerald-50" : "from-indigo-50"} to-white flex flex-col gap-4`}
    >
      <div className="flex gap-4 justify-start items-center">
        <div className="w-6 h-6 relative bg-white rounded flex justify-center items-center">
          <img
            src={getTypeDetails(type).icon}
            alt={getTypeDetails(type).title}
            className="w-4 h-4 flex-none"
          />
        </div>
        <div className="flex justify-start items-center gap-2">
          <div className="text-body-large text-black">
            {getTypeDetails(type).title}
          </div>
          <div className="text-body-medium text-zinc-500">
            ({getTypeDetails(type).time})
          </div>
        </div>
      </div>
      <div className="w-full p-4 rounded-lg bg-white shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] flex justify-start items-start gap-2">
        <img
          src={image}
          alt={name}
          className="w-12 h-12 rounded-lg object-cover"
        />
        <div className="flex justify-between items-center w-full">
          <div className="flex flex-col gap-1">
            <div className="text-body-medium text-black">{name}</div>
            <div className="flex justify-start items-center gap-2 flex-wrap">
              <div className="px-2 py-1 bg-white/60 rounded-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] flex justify-center items-center">
                <div className="text-neutral-700 text-body-medium">
                  {t("kcal_count", { count: calories })}
                </div>
              </div>
              <div className="px-2 py-1 bg-white/60 rounded-xl shadow-[0px_4px_20px_0px_rgba(0,0,0,0.06)] flex justify-center items-center">
                <div className="text-neutral-700 text-body-medium">{time}</div>
              </div>
            </div>
          </div>
          {showLogButton && <SecondaryButton text={t("log")} />}
        </div>
      </div>
    </div>
  );
};

export default MealWithDishCard;
