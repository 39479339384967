export default {
  "+91": /^\d{10}$/,
  "+93": /^\d{9}$/,
  "+355": /^\d{8,9}$/,
  "+213": /^\d{9}$/,
  "+1-684": /^\d{7}$/,
  "+376": /^\d{6,9}$/,
  "+244": /^\d{9}$/,
  "+1-264": /^\d{7}$/,
  "+672": /^\d{6,9}$/,
  "+1-268": /^\d{7}$/,
  "+54": /^\d{9,10}$/,
  "+374": /^\d{8}$/,
  "+297": /^\d{7}$/,
  "+61": /^\d{9}$/,
  "+43": /^\d{10}$/,
  "+994": /^\d{9,10}$/,
  "+1-242": /^\d{7}$/,
  "+973": /^\d{8}$/,
  "+880": /^\d{10}$/,
  "+1-246": /^\d{7}$/,
  "+375": /^\d{9}$/,
  "+32": /^\d{9}$/,
  "+501": /^\d{7}$/,
  "+229": /^\d{8}$/,
  "+1-441": /^\d{7}$/,
  "+975": /^\d{8}$/,
  "+591": /^\d{8}$/,
  "+387": /^\d{8,9}$/,
  "+267": /^\d{7,8}$/,
  "+55": /^\d{10,11}$/,
  "+246": /^\d{7}$/,
  "+673": /^\d{7,8}$/,
  "+359": /^\d{8,9}$/,
  "+226": /^\d{8}$/,
  "+257": /^\d{8}$/,
  "+855": /^\d{8,9}$/,
  "+237": /^\d{9}$/,
  "+1": /^\d{10}$/,
  "+238": /^\d{7}$/,
  "+1-345": /^\d{7}$/,
  "+236": /^\d{8}$/,
  "+235": /^\d{8}$/,
  "+56": /^\d{9}$/,
  "+86": /^\d{11}$/,
  "+57": /^\d{10}$/,
  "+269": /^\d{7}$/,
  "+242": /^\d{9}$/,
  "+243": /^\d{9}$/,
  "+682": /^\d{5}$/,
  "+506": /^\d{8}$/,
  "+225": /^\d{8}$/,
  "+385": /^\d{8,9}$/,
  "+53": /^\d{8}$/,
  "+357": /^\d{8}$/,
  "+420": /^\d{9}$/,
  "+45": /^\d{8}$/,
  "+253": /^\d{8}$/,
  "+1-767": /^\d{7}$/,
  "+1-809": /^\d{7}$/,
  "+593": /^\d{9}$/,
  "+20": /^\d{10}$/,
  "+503": /^\d{8}$/,
  "+240": /^\d{9}$/,
  "+291": /^\d{7}$/,
  "+372": /^\d{7,8}$/,
  "+268": /^\d{8}$/,
  "+251": /^\d{9}$/,
  "+500": /^\d{5}$/,
  "+298": /^\d{6}$/,
  "+679": /^\d{7}$/,
  "+358": /^\d{9}$/,
  "+33": /^\d{9}$/,
  "+594": /^\d{9}$/,
  "+689": /^\d{8}$/,
  "+241": /^\d{7,8}$/,
  "+220": /^\d{7}$/,
  "+995": /^\d{9}$/,
  "+49": /^\d{10,11}$/,
  "+233": /^\d{9}$/,
  "+350": /^\d{8}$/,
  "+30": /^\d{10}$/,
  "+299": /^\d{6}$/,
  "+1-473": /^\d{7}$/,
  "+590": /^\d{9}$/,
  "+1-671": /^\d{7}$/,
  "+44": /^\d{10}$/,
  "+224": /^\d{9}$/,
  "+245": /^\d{7}$/,
  "+592": /^\d{7}$/,
  "+509": /^\d{8}$/,
  "+504": /^\d{8}$/,
  "+852": /^\d{8}$/,
  "+36": /^\d{9}$/,
  "+354": /^\d{7}$/,
  "+62": /^\d{9,10}$/,
  "+98": /^\d{10}$/,
  "+964": /^\d{10}$/,
  "+353": /^\d{9}$/,
  "+972": /^\d{9}$/,
  "+1-876": /^\d{7}$/,
  "+81": /^\d{9,10}$/,
  "+962": /^\d{8,9}$/,
  "+7": /^\d{10}$/,
  "+254": /^\d{9}$/,
  "+686": /^\d{5}$/,
  "+850": /^\d{9}$/,
  "+965": /^\d{8}$/,
  "+996": /^\d{9}$/,
  "+856": /^\d{8,9}$/,
  "+371": /^\d{8}$/,
  "+961": /^\d{7,8}$/,
  "+266": /^\d{8}$/,
  "+231": /^\d{7,8}$/,
  "+218": /^\d{9}$/,
  "+423": /^\d{7,8}$/,
  "+370": /^\d{8}$/,
  "+352": /^\d{8}$/,
  "+853": /^\d{8}$/,
  "+389": /^\d{8}$/,
  "+261": /^\d{9}$/,
  "+265": /^\d{9}$/,
  "+60": /^\d{9}$/,
  "+960": /^\d{7}$/,
  "+223": /^\d{8}$/,
  "+356": /^\d{8}$/,
  "+692": /^\d{7}$/,
  "+596": /^\d{9}$/,
  "+222": /^\d{8}$/,
  "+230": /^\d{8}$/,
  "+262": /^\d{9}$/,
  "+52": /^\d{10}$/,
  "+691": /^\d{7}$/,
  "+373": /^\d{8}$/,
  "+377": /^\d{8}$/,
  "+976": /^\d{8}$/,
  "+382": /^\d{8}$/,
  "+1-664": /^\d{7}$/,
  "+212": /^\d{9}$/,
  "+258": /^\d{9}$/,
  "+95": /^\d{8,9}$/,
  "+264": /^\d{9}$/,
  "+674": /^\d{7}$/,
  "+977": /^\d{8,10}$/,
  "+31": /^\d{9}$/,
  "+599": /^\d{7}$/,
  "+687": /^\d{6}$/,
  "+64": /^\d{8,9}$/,
  "+505": /^\d{8}$/,
  "+227": /^\d{8}$/,
  "+234": /^\d{10}$/,
  "+683": /^\d{4}$/,
  "+1-670": /^\d{7}$/,
  "+968": /^\d{8}$/,
  "+92": /^\d{10}$/,
  "+680": /^\d{7}$/,
  "+970": /^\d{8}$/,
  "+507": /^\d{8}$/,
  "+675": /^\d{7}$/,
  "+595": /^\d{9}$/,
  "+51": /^\d{9}$/,
  "+63": /^\d{10}$/,
  "+872": /^\d{7}$/,
  "+48": /^\d{9}$/,
  "+351": /^\d{9}$/,
  "+1-787": /^\d{7}$/,
  "+974": /^\d{8}$/,
  "+40": /^\d{9}$/,
  "+250": /^\d{9}$/,
  "+290": /^\d{4}$/,
  "+1-869": /^\d{7}$/,
  "+1-758": /^\d{7}$/,
  "+508": /^\d{6}$/,
  "+1-784": /^\d{7}$/,
  "+685": /^\d{7}$/,
  "+378": /^\d{8,9}$/,
  "+239": /^\d{7}$/,
  "+966": /^\d{9}$/,
  "+221": /^\d{9}$/,
  "+381": /^\d{8,9}$/,
  "+248": /^\d{7}$/,
  "+232": /^\d{8}$/,
  "+65": /^\d{8}$/,
  "+421": /^\d{9}$/,
  "+386": /^\d{8}$/,
  "+677": /^\d{7}$/,
  "+252": /^\d{8,9}$/,
  "+27": /^\d{9}$/,
  "+82": /^\d{8,9}$/,
  "+211": /^\d{9}$/,
  "+34": /^\d{9}$/,
  "+94": /^\d{9}$/,
  "+597": /^\d{7}$/,
  "+47": /^\d{8}$/,
  "+46": /^\d{7,9}$/,
  "+41": /^\d{9}$/,
  "+963": /^\d{9}$/,
  "+886": /^\d{9}$/,
  "+992": /^\d{9}$/,
  "+255": /^\d{9}$/,
  "+66": /^\d{9}$/,
  "+670": /^\d{7}$/,
  "+228": /^\d{8}$/,
  "+690": /^\d{4}$/,
  "+676": /^\d{7}$/,
  "+1-868": /^\d{7}$/,
  "+216": /^\d{8}$/,
  "+90": /^\d{10}$/,
  "+993": /^\d{8}$/,
  "+1-649": /^\d{7}$/,
  "+688": /^\d{5}$/,
  "+256": /^\d{9}$/,
  "+380": /^\d{9}$/,
  "+971": /^\d{8,9}$/,
  "+598": /^\d{8}$/,
  "+998": /^\d{9}$/,
  "+678": /^\d{7}$/,
  "+39": /^\d{8,10}$/,
  "+58": /^\d{10}$/,
  "+84": /^\d{9}$/,
  "+1-284": /^\d{7}$/,
  "+1-340": /^\d{7}$/,
  "+681": /^\d{6}$/,
  "+967": /^\d{9}$/,
  "+260": /^\d{9}$/,
  "+263": /^\d{9}$/,
};
