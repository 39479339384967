import { Divider, message } from "antd";
import {
  queryProducts,
  launchPurchaseNative,
} from "../../utilities/paymentsHelper";
import { useEffect, useState } from "react";

const PaymentsTestPage = () => {
  const [status, setStatus] = useState("");
  const [productId, setProductId] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const receiveProductDetailsHandler = (data) => {
    try {
      const dataParsed = JSON.parse(data);
      setProductDetails(dataParsed.toString());
    } catch (e) {
      setProductDetails(data);
      messageApi.error(data);
    }
  };

  const receivePurchaseDetailsHandler = (data) => {
    try {
      const dataParsed = JSON.parse(data);
      setStatus(dataParsed.toString());
      messageApi.success(dataParsed);
    } catch (e) {
      setStatus(data);
      messageApi.error(data);
    }
  };

  useEffect(() => {
    window.receiveProductDetails = receiveProductDetailsHandler;
    window.receivePurchaseDetails = receivePurchaseDetailsHandler;
    return () => {
      window.receiveProductDetails = null;
      window.receivePurchaseDetails = null;
    };
  }, []);

  return (
    <div className="min-h-full flex flex-col gap-4 items-center justify-center">
      {contextHolder}
      <h1>Payments Test</h1>
      <input
        type="text"
        className="border"
        placeholder="Product ID"
        value={productId}
        onChange={(e) => setProductId(e.target.value)}
      />
      <p>Product details: {productDetails}</p>
      <button className="border" onClick={() => queryProducts([productId])}>
        Get Product Details
      </button>
      <Divider />
      <p>Status: {status}</p>
      <button
        className="border"
        onClick={() =>
          launchPurchaseNative("test_in_app_product_4", "test_order_id_4", {
            test: "test",
          })
        }
      >
        Launch Purchase
      </button>
      <Divider />
      <button
        className="border"
        onClick={() => {
          window.location.reload();
        }}
      >
        RESET
      </button>
    </div>
  );
};

export default PaymentsTestPage;
