import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropdownField({
  value,
  setValue,
  onChange,
  label,
  optionsData,
}) {
  const onChangeHandler = (event) => {
    onChange(event.target.value);
    setValue(event.target.value);
  };

  return (
    <FormControl
      variant="outlined"
      className="w-full bg-white shadow rounded-xl z-10"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px #DADDE1 solid",
            borderRadius: "0.75rem",
          },
          "&:hover fieldset": {
            border: "1px #1D1B20 solid",
          },
          "&.Mui-focused fieldset": {
            border: "3px #F27521 solid",
          },
        },
        "& .MuiInputLabel-root": {
          color: "#1D1B20",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#F27521",
        },
      }}
    >
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        label={label}
        onChange={onChangeHandler}
      >
        {optionsData &&
          optionsData.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label} ({option.value})
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
