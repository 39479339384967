import * as SERVICEs from "../services/Settings";

export const getPrompt = (agent) => {
  return SERVICEs.getPrompt(agent)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePrompt = (agent, prompt_id) => {
  return SERVICEs.deletePrompt(agent, prompt_id)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updatePrompt = (agent, data) => {
  return SERVICEs.updatePrompt(agent, data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const profileConfig = () => {
  return SERVICEs.profileConfig()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getLanguages = () => {
  return SERVICEs.getLanguages()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getDoctorConfig = (type) => {
  return SERVICEs.getDoctorConfig(type)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getReportFaqs = (type) => {
  return SERVICEs.getReportFaqs(type)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getGenericContent = (payload) => {
  return SERVICEs.getGenericContent(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getConfig = (type) => {
  return SERVICEs.getConfig(type)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const getProducts = (data = {}) => {
  return SERVICEs.getProducts(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateOrderDetails = (payload) => {
  return SERVICEs.updateOrderDetails(payload)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
};
