import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { withStore } from "./withStore";

export default (ChildComponnent) => {
  class RequireAuth extends Component {
    constructor() {
      super();
    }

    render() {
      const {
        store: { data },
      } = this.props;

      if (!data?.token) {
        return (
          <Navigate
            to={`/welcome?referrer=${encodeURIComponent(window.location.href)}`}
            replace
          />
        );
      } else {
        return <ChildComponnent {...this.props} />;
      }
    }
  }

  return withStore(RequireAuth);
};
