import successIcon from "../assets/images/payment/success.svg";
import failedIcon from "../assets/images/payment/failed.svg";
import pendingIcon from "../assets/images/payment/pending.svg";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "../subComponents/PrimaryButton";

const PaymentStatusPage = () => {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const redirect = searchParams.get("redirect");
  const navigate = useNavigate();
  const location = useLocation();
  const billingData = location?.state?.data;

  const paymentStatuses = {
    success: {
      icon: successIcon,
      status: ["success"],
      title: "Congratulations!",
      subtitle: "Your first purchase was successful!",
      ctaText: "Continue",
      ctaClickHandler: () => {
        navigate(redirect, { replace: true });
      },
    },
    pending: {
      icon: pendingIcon,
      status: ["pending"],
      title: "Uh-Oh! Please wait...",
      subtitle: "Please wait while we retrieve your payment status.",
      ctaText: "Back",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    cancelled: {
      icon: pendingIcon,
      status: ["cancelled"],
      title: "Oops! Payment cancelled.",
      subtitle: `Your payment has been cancelled. Please try again.`,
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    failed: {
      icon: failedIcon,
      status: ["failed", "error"],
      title: "Oops! Payment failed.",
      subtitle: "Your payment has failed. Please try again.",
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
    invalid: {
      icon: failedIcon,
      status: ["invalid"],
      title: "Oops! Invalid Data received.",
      subtitle: `Your payment has failed. Please try again. ${
        billingData ? billingData : ""
      }`,
      ctaText: "Retry Payment",
      ctaClickHandler: () => {
        navigate(-1, { replace: true });
      },
    },
  };

  return (
    <div className="min-h-full flex flex-col items-center justify-center">
      {status && (
        <PaymentStatusComponent
          {...Object.values(paymentStatuses).find((value) =>
            value.status.includes(status)
          )}
        />
      )}
    </div>
  );
};

const PaymentStatusComponent = ({
  icon,
  title,
  subtitle,
  ctaText,
  ctaClickHandler,
}) => {
  return (
    <div className="flex flex-col items-center gap-4">
      <img height={70} width={70} src={icon} alt="payment status" />
      <p className="text-body-large">{title}</p>
      <p className="text-body-medium opacity-80 break-all">{subtitle}</p>
      {ctaText && (
        <PrimaryButton
          text={ctaText}
          className="bg-primary text-white py-2 px-4 rounded mt-4"
          onClick={() => ctaClickHandler()}
        />
      )}
    </div>
  );
};

export default PaymentStatusPage;
