import { logSentryError } from "./helper";

export const queryProducts = (productIds) => {
  if (productIds.length === 0) {
    return;
  }
  const stringifiedProductIds = JSON.stringify(productIds);
  try {
    if (typeof AndroidBillingInterface !== typeof undefined) {
      AndroidBillingInterface.queryProducts(stringifiedProductIds);
    }
    if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "iapProductInfo",
        data: {
          productIds: stringifiedProductIds,
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};

export const launchPurchaseNative = (
  productId,
  orderId,
  developerPayload = {
    payload: "",
  },
  type = "inapp"
) => {
  if (!productId || !orderId) {
    return;
  }

  try {
    if (typeof AndroidBillingInterface !== typeof undefined) {
      AndroidBillingInterface.launchPurchase(
        productId,
        orderId,
        JSON.stringify(developerPayload),
        type
      );
    }
    if (window.webkit?.messageHandlers?.iosInterface) {
      window.webkit.messageHandlers.iosInterface.postMessage({
        action: "purchaseProduct",
        data: {
          productId: productId,
          orderId: orderId,
          developerPayload: developerPayload?.payload || "",
        },
      });
    }
  } catch (e) {
    logSentryError(e);
  }
};
