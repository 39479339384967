import { useState, useCallback, useEffect, useRef } from "react";
import { getPaymentDetails, initPayment } from "../dataManager/Payments";
import { launchPurchaseNative } from "../utilities/paymentsHelper";
import { getProducts, updateOrderDetails } from "../dataManager/Settings";
import { isIosApp, isJson, logSentryError } from "../utilities/helper";
import * as Sentry from "@sentry/react";

const usePayments = ({
  productName,
  autoCheckForExistingPurchase = true,
  onStatusUpdate,
  onSuccess,
  onError = () => {},
  onCancel = () => {},
  onPending = () => {},
  onSuccessEvent = () => {},
  onInvalidData = () => {},
  onBackendError = () => {},
}) => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const pollingInterval = useRef(null);
  const [isAlreadyPurchased, setIsAlreadyPurchased] = useState(false);
  const [billingData, setBillingData] = useState(JSON.stringify({}));

  const stopPolling = useCallback(() => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
      setIsPolling(false);
    }
  }, []);

  useEffect(() => {
    return () => stopPolling();
  }, [stopPolling]);

  const onPaymentStatusUpdate = (data) => {
    if (isJson(data)) {
      if (!isIosApp()) {
        updateOrderDetails({
          ...JSON.parse(data),
          orderId: orderDetails?.orderId,
        });
      }
      setBillingData(data);
    } else {
      setBillingData(JSON.stringify({ type: "invalid_data", data: data }));
    }
  };
  useEffect(() => {
    window.onBillingUpdate = (data) => {
      onPaymentStatusUpdate(data);
    };
  }, []);

  useEffect(() => {
    window.receivePurchaseDetails = (data) => {
      onPaymentStatusUpdate(data);
    };
  }, []);

  useEffect(() => {
    const type = JSON.parse(billingData)?.type;
    switch (type) {
      case undefined:
      case null:
      case "":
        break;
      case "canceled":
        onCancel();
        break;
      case "service_unavailable":
      case "billing_unavailable":
      case "item_unavailable":
      case "developer_error":
      case "item_already_owned":
      case "system_error":
      case "product_not_found":
      case "error":
        onError();
        break;
      case "purchase_pending":
      case "purchase_unverified":
        onPending();
        break;
      case "purchase_verified":
      case "purchase_acknowledged":
      case "purchase_success":
        onSuccessEvent();
        break;
      case "invalid_data":
        onInvalidData(JSON.parse(billingData).data);
        break;
      case "backend_error":
        onBackendError(JSON.parse(billingData).message);
        break;
    }
  }, [billingData]);

  useEffect(() => {
    if (!autoCheckForExistingPurchase) return;
    const checkIfAlreadyPurchased = () => {
      getProducts({ status: "SUCCESS" })
        .then((res) => {
          const planDetails = res?.data?.data?.plans.find(
            (plan) =>
              plan.status === "SUCCESS" &&
              plan.planDetails?.code === productName
          );
          if (planDetails) {
            setIsAlreadyPurchased(true);
            setOrderDetails(planDetails);
          } else {
            setIsAlreadyPurchased(false);
          }
        })
        .catch((err) => {
          logSentryError(err);
          setIsAlreadyPurchased(false);
        });
    };

    checkIfAlreadyPurchased();
  }, [productName]);

  const checkOrderStatus = useCallback(
    async (orderId) => {
      try {
        const response = await getPaymentDetails(orderId);

        if (!response.status === 200) {
          throw new Error("Failed to check order status");
        }

        const data = response.data;

        const status = data.data.status;

        onStatusUpdate?.(status);

        if (status === "SUCCESS") {
          onSuccess?.(data);
          stopPolling();
        } else if (status === "FAILED") {
          onError?.(new Error("Payment failed"));
          stopPolling();
        }

        return data;
      } catch (error) {
        console.error("Error checking order status:", error);
        onError?.(error);
        stopPolling();
        throw error;
      }
    },
    [onStatusUpdate, onSuccess, onError, stopPolling]
  );

  const startPolling = useCallback(
    (orderId) => {
      if (isPolling) return;

      setIsPolling(true);
      checkOrderStatus(orderId);

      pollingInterval.current = setInterval(() => {
        checkOrderStatus(orderId);
      }, 5000);
    },
    [isPolling, checkOrderStatus]
  );

  const initiatePayment = useCallback(async () => {
    try {
      const response = await initPayment(productName);

      if (!response.status === 200) {
        throw new Error("Failed to initiate payment");
      }

      const data = response.data;

      setOrderDetails(data.data);

      if (data?.data?.orderId && data?.data?.productId) {
        launchPurchaseNative(
          data.data.productId,
          data.data.orderId,
          {
            payload: data?.data?.developerPayload || "",
          },
          data.data.type
        );
        startPolling(data.data.orderId);
      }

      return data;
    } catch (error) {
      console.error("Error initiating payment:", error);
      onError?.(error);
      throw error;
    }
  }, [productName, onError, startPolling]);

  return {
    initiatePayment,
    orderDetails,
    isPolling,
    isAlreadyPurchased,
    billingData,
  };
};

export default usePayments;
