import {
  OutlinedInput,
  InputAdornment,
  InputLabel,
  FormControl,
} from "@mui/material";

const InputField = ({
  value,
  setValue,
  label,
  endIcon,
  error,
  type = "text",
  onGo,
}) => {
  return (
    <FormControl
      error={!!error}
      variant="outlined"
      className="w-full bg-white shadow rounded-xl z-10"
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "1px #DADDE1 solid",
            borderRadius: "0.75rem",
          },
          "&:hover fieldset": {
            border: "1px #1D1B20 solid",
          },
          "&.Mui-focused fieldset": {
            border: "3px #F27521 solid",
          },
        },
        "& .MuiInputLabel-root": {
          color: "#1D1B20",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: "#F27521",
        },
      }}
    >
      <InputLabel htmlFor="outlined-adornment-mobile">{label}</InputLabel>
      <OutlinedInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          switch (e.key) {
            case "Enter":
              e.preventDefault();
              onGo();
              break;
            default:
              break;
          }
        }}
        id="outlined-adornment-mobile"
        type={type}
        endAdornment={<InputAdornment position="end">{endIcon}</InputAdornment>}
        label={label}
      />
    </FormControl>
  );
};

export default InputField;
