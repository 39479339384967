import { useEffect, useRef } from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { Box } from "@mui/system";
import "../assets/css/OtpInput.css";

const OtpInput = ({ length, value, onChange, error, onGo }) => {
  const inputRefs = useRef(new Array(length).fill(null));

  useEffect(() => {
    const targetInput = inputRefs.current[value.length >= 6 ? 6 : value.length];
    targetInput.focus();
  }, []);

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
  };

  const selectInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event, currentIndex) => {
    switch (event.key) {
      case "ArrowUp":
      case "ArrowDown":
      case "e":
      case "E":
      case " ":
        event.preventDefault();
        break;
      case "ArrowLeft":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }
        break;
      case "ArrowRight":
        event.preventDefault();
        if (currentIndex < length - 1) {
          focusInput(currentIndex + 1);
          selectInput(currentIndex + 1);
        }
        break;
      case "Delete":
        event.preventDefault();
        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });

        break;
      case "Enter":
        event.preventDefault();
        onGo();
        break;
      case "Backspace":
        event.preventDefault();
        if (currentIndex > 0) {
          focusInput(currentIndex - 1);
          selectInput(currentIndex - 1);
        }

        onChange((prevOtp) => {
          const otp =
            prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1);
          return otp;
        });
        break;

      default:
        break;
    }
  };

  const handleChange = (event, currentIndex) => {
    const currentValue = event.target.value;
    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (
        inputRefs.current[indexToEnter].value &&
        indexToEnter < currentIndex
      ) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange((prev) => {
      const otpArray = prev.split("");
      const lastValue = currentValue[currentValue.length - 1];
      otpArray[indexToEnter] = lastValue;
      return otpArray.join("");
    });
    if (currentValue !== "") {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      }
    }
  };

  const handleClick = (event, currentIndex) => {
    selectInput(currentIndex);
  };

  const handlePaste = (event, currentIndex) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;
    if (clipboardData.types.includes("text/plain")) {
      let pastedText = clipboardData
        .getData("text/plain")
        .substring(0, length)
        .trim();
      if (pastedText.match(/\d+/)) {
        let indexToEnter = 0;
        while (indexToEnter <= currentIndex) {
          if (
            inputRefs.current[indexToEnter].value &&
            indexToEnter < currentIndex
          ) {
            indexToEnter += 1;
          } else {
            break;
          }
        }

        const otpArray = value.split("");

        for (let i = indexToEnter; i < length; i += 1) {
          const lastValue = pastedText[i - indexToEnter] ?? " ";
          otpArray[i] = lastValue;
        }

        onChange(otpArray.join(""));
      }
    }
  };

  return (
    <Box className="flex justify-center items-center w-full gap-1">
      {new Array(length).fill(null).map((_, index) => (
        <BaseInput
          key={index}
          slots={{
            input: "input",
          }}
          aria-label={`Digit ${index + 1} of OTP`}
          slotProps={{
            input: {
              type: "number",
              ref: (ele) => {
                inputRefs.current[index] = ele;
              },
              onKeyDown: (event) => handleKeyDown(event, index),
              onChange: (event) => handleChange(event, index),
              onClick: (event) => handleClick(event, index),
              onPaste: (event) => handlePaste(event, index),
              value: value[index] ?? "",
              placeholder: 0,
              className: `placeholder-slate-200 otpInput w-full h-16 ${value[index] ? "bg-orange-50" : "bg-slate-50"} rounded-2xl text-center text-black text-heading-large ${error ? "error" : ""}`,
            },
          }}
        />
      ))}
    </Box>
  );
};

export default OtpInput;
